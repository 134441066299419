<template>
  <div class="max-w-7xl mx-auto p-6 flex flex-wrap">
    <div class="w-full md:flex md:items-center p-4 space-x-0 md:space-x-2">
      <input v-model="searchQuery" @keyup.enter="searchAddress" placeholder="Digite o endereço do imóvel..."
        class="form-input w-full md:flex-1 border-2 border-gray-300 focus:border-yellow-500 rounded-t-md md:rounded-l-md md:rounded-t-none p-2 shadow-sm transition-colors">
      <button @click="searchAddress"
        class="bg-yellow-500 hover:bg-yellow-600 text-white font-bold p-2 rounded-b-md md:rounded-r-md md:rounded-b-none transition-colors w-full md:w-auto">
        Pesquisar
      </button>
    </div>

    <l-map :center="mapCenter" :zoom="zoomLevel" style="height: 400px; width: 100%">
      <l-tile-layer :url="tileUrl" />
      <l-marker :lat-lng="mapCenter">
        <l-popup>{{ searchQuery }}</l-popup>
      </l-marker>
    </l-map>
  </div>
</template>

  
  <script setup>
  import { ref } from 'vue';
  import { LMap, LTileLayer, LMarker, LPopup } from '@vue-leaflet/vue-leaflet';
  import 'leaflet/dist/leaflet.css';

  const searchQuery = ref('');
  const mapCenter = ref([-14.8648, -40.8369]);
  const zoomLevel = ref(13); 
  const tileUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  
  function searchAddress() {
    if (searchQuery.value) {
      const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(searchQuery.value)}`;
  
      fetch(url)
        .then(response => response.json())
        .then(data => {
          if (data.length > 0) {
            const { lat, lon } = data[0];
            mapCenter.value = [lat, lon];
            zoomLevel.value = 15; 
          } else {
            alert('Endereço não encontrado');
          }
        })
        .catch(error => {
          console.error('Erro na busca do endereço:', error);
          alert('Falha ao buscar o endereço');
        });
    }
  }
  </script>
  