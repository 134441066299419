<template>
    <footer class="bg-gradient-to-t from-gray-700 via-gray-600 to-gray-500 text-white text-center p-4">
        <div class="container mx-auto px-4">
            <div class="flex flex-wrap justify-center items-center">
                <div class="w-full md:w-1/4 p-4">
                    <img src="https://i.ibb.co/RpzLpPm/Whats-App-Image-2024-05-07-at-22-14-13-removebg-preview.png"
                        alt="Logo Álvaro Motta Imóveis" class="mx-auto h-20">
                </div>
                <div class="w-full md:w-3/4 p-4 md:flex md:justify-between">
                    <div>
                        <h3 class="text-lg font-bold">Alvaro Motta Imóveis</h3>
                        <p>Encontre seu espaço ideal conosco!</p>
                    </div>
                    <div>
                        <h3 class="text-lg font-bold">Siga-nos</h3>
                        <ul class="flex justify-center space-x-4">
                            <li><a href="https://www.facebook.com/alvaromottaimoveis" target="_blank"
                                    class="hover:underline">Facebook</a></li>
                            <li><a href="https://www.instagram.com/alvaromotta._/" target="_blank"
                                    class="hover:underline">Instagram</a></li>
                            <li><a href="https://www.imovelweb.com.br/imobiliarias/alvaro-motta_47613244-imoveis.html"
                                    target="_blank" class="hover:underline">Imóvel Web</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="text-center p-4 mt-4 border-t border-gray-700">
                © 2024 Alvaro Motta Imóveis - Creci 7624. Todos os direitos reservados.
            </div>
            <div class="text-sm text-gray-500">
                Desenvolvido por <a href="https://www.gabimottadev.com" class="hover:underline"
                    target="_blank">gabimottadev</a>
            </div>
        </div>
    </footer>
</template>

<script setup>
</script>
