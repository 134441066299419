<template>
    <div class="flex flex-col min-h-screen">
        <Header />
        <br />
        <div class="flex items-center justify-center min-h-screen bg-gray-100">
            <form @submit.prevent="submitContactForm"
                class="space-y-6 bg-white p-12 rounded-lg shadow-lg max-w-4xl w-full">
                <div class="flex flex-col items-center justify-center w-full">
                    <img src="https://i.ibb.co/RpzLpPm/Whats-App-Image-2024-05-07-at-22-14-13-removebg-preview.png"
                        alt="Logo Álvaro Motta Imóveis" class="h-20 mb-4">
                    <h2 class="text-3xl font-bold text-gray-900">Fale Conosco</h2>
                </div>
                <div class="grid grid-cols-1 gap-y-6">
                    <input type="tel" v-model="contactForm.telephone" id="telephone" required @input="formatPhone"
                        maxlength="15"
                        class="form-input block w-full shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                        placeholder="Seu Telefone">
                    <input type="text" v-model="contactForm.name" id="name" required
                        class="form-input block w-full shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                        placeholder="Seu nome">
                    <textarea v-model="contactForm.message" id="message" rows="6" required
                        class="form-input block w-full shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                        placeholder="Qual o tipo de Imóvel que procura?"></textarea>
                    <select v-model="contactForm.type" id="type" required
                        class="form-select block w-full shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500">
                        <option disabled value="">Como você nos encontrou?</option>
                        <option value="Google">Google</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Instagram">Instagram</option>
                        <option value="Amigo/Família">Amigo/Família</option>
                        <option value="Outro">Outro</option>
                    </select>
                    <button type="submit"
                        class="w-full flex justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                        Enviar Mensagem</button>
                </div>
            </form>
        </div>
        <div class="w-full flex justify-center py-6 bg-gray-200">
            <a href="https://www.facebook.com/alvaromottaimoveis" class="mx-2">
                <img src="https://cdn-icons-png.flaticon.com/256/124/124010.png" alt="Facebook" class="h-6 w-6" />
            </a>
            <a href="https://www.instagram.com/alvaromotta._/" class="mx-2">
                <img src="https://cdn-icons-png.flaticon.com/512/1384/1384063.png" alt="Instagram" class="h-6 w-6" />
            </a>
            <a href="https://wa.me/77988059201?text=Olá!%20Gostaria%20de%20mais%20informações." class="mx-2">
                <img src="https://cdn-icons-png.flaticon.com/512/174/174879.png" alt="Whatsapp" class="h-6 w-6" />
            </a>
            <a href="https://www.linkedin.com/in/%C3%A1lvaro-motta-89765315b/" class="mx-2">
                <img src="https://cdn-icons-png.flaticon.com/512/3670/3670129.png" alt="LinkedIn" class="h-6 w-6" />
            </a>
        </div>
        <Footer />
        <WhatsappLink />
    </div>
</template>
  
  <script setup>
import { ref } from 'vue'
import Header from '@/components/PageHeader.vue'
import Footer from '@/components/PageFooter.vue'
import WhatsappLink from '@/components/WhatsappLink.vue'

const contactForm = ref({
    telephone: '',
    name: '',
    message: '',
    type: ''
})

const formatPhone = event => {
    let value = event.target.value
    value = value.replace(/\D/g, '')
    value = value.replace(/^(\d{2})(\d)/g, "($1) $2")
    value = value.replace(/(\d)(\d{4})$/, "$1-$2")
    event.target.value = value
    contactForm.value.telephone = value
}

const submitContactForm = () => {
    const { telephone, name, message, type } = contactForm.value
    const whatsappNumber = '77988059201'
    const text = `Olá! Meu nome é ${name}. Meu telefone é ${telephone}. Estou procurando por: ${message}. Como você nos encontrou: ${type}.`
    const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(text)}`
    window.open(whatsappURL, '_blank')
}
</script>
  