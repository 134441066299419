<template>
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1
            class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-yellow-500 to-yellow-500 text-transparent bg-clip-text">
            Visualizar Imóveis</h1>
        <br />
        <input v-model="search" class="mb-4 p-2 w-full border rounded" placeholder="Pesquisar imóvel...">
        <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
                <tr>
                    <th scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nome</th>
                    <th scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Preço
                    </th>
                    <th scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ações
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="imovel in filteredImoveis" :key="imovel.id">
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{{ imovel.name }}</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{{ formatCurrency(imovel.price) }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            @click="verDetalhes(imovel)">Ver Detalhes</button>
                        <button class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
                            @click="editarImovel(imovel)">Editar</button>
                        <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            @click="excluirImovel(imovel.id)">Excluir</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div v-if="imovelSelecionado" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div class="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full">
                <h2 class="text-2xl font-bold mb-4">{{ imovelSelecionado.name }}</h2>
                <p><strong>Preço:</strong> {{ formatCurrency(imovelSelecionado.price) }}</p>
                <p><strong>Descrição:</strong> {{ imovelSelecionado.description }}</p>
                <p><strong>Localização:</strong> {{ imovelSelecionado.location }}</p>
                <p><strong>Cidade:</strong> {{ imovelSelecionado.city }}</p>
                <p><strong>Tipo:</strong> {{ imovelSelecionado.type }}</p>
                <p><strong>Quartos:</strong> {{ imovelSelecionado.bedrooms }}</p>
                <p><strong>Banheiros:</strong> {{ imovelSelecionado.bathrooms }}</p>
                <p><strong>Suítes:</strong> {{ imovelSelecionado.suites }}</p>
                <p><strong>Área Útil:</strong> {{ imovelSelecionado.area }} m²</p>
                <p><strong>Área Total:</strong> {{ imovelSelecionado.areaTotal }} m²</p>
                <button class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    @click="imovelSelecionado = null">Fechar</button>
            </div>
        </div>

        <div v-if="imovelParaEditar" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div class="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full max-h-screen overflow-y-auto">
                <h2 class="text-2xl font-bold mb-4">Editar {{ imovelParaEditar.name }}</h2>
                <form @submit.prevent="atualizarImovel">
                    <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <div class="col-span-1">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Nome</label>
                            <input v-model="imovelAtualizado.name"
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="name" type="text" required>
                        </div>
                        <div class="col-span-1">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="price">Preço</label>
                            <input v-model="imovelAtualizado.price"
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="price" type="number" required>
                        </div>
                        <div class="col-span-2">
                            <label class="block text-gray-700 text-sm font-bold mb-2"
                                for="description">Descrição</label>
                            <textarea v-model="imovelAtualizado.description"
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="description" required></textarea>
                        </div>
                        <div class="col-span-2">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="location">Localização</label>
                            <input v-model="imovelAtualizado.location"
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="location" type="text" required>
                        </div>
                        <div class="col-span-1">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="city">Cidade</label>
                            <input v-model="imovelAtualizado.city"
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="city" type="text" required>
                        </div>
                        <div class="col-span-1">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="type">Tipo</label>
                            <select v-model="imovelAtualizado.type"
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="type" required>
                                <option value="Casa">Casa</option>
                                <option value="Apartamento">Apartamento</option>
                                <option value="Casa em Condominio">Casa em Condominio</option>
                                <option value="Terreno">Terreno</option>
                                <option value="Estúdio">Estúdio</option>
                                <option value="Sítio">Sítio</option>
                                <option value="Fazenda">Fazenda</option>
                                <option value="Ponto Comercial">Ponto Comercial</option>
                                <option value="Loja">Loja</option>
                                <option value="Sala">Sala</option>
                                <option value="Outro">Outro</option>
                            </select>
                        </div>
                        <div class="col-span-1">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="bedrooms">Quartos</label>
                            <input v-model="imovelAtualizado.bedrooms"
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="bedrooms" type="number" required>
                        </div>
                        <div class="col-span-1">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="bathrooms">Banheiros</label>
                            <input v-model="imovelAtualizado.bathrooms"
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="bathrooms" type="number" required>
                        </div>
                        <div class="col-span-1">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="suites">Suítes</label>
                            <input v-model="imovelAtualizado.suites"
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="suites" type="number" required>
                        </div>
                        <div class="col-span-1">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="area">Área Útil</label>
                            <input v-model="imovelAtualizado.area"
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="area" type="number" required>
                        </div>
                        <div class="col-span-1">
                            <label class="block text-gray-700 text-sm font-bold mb-2" for="areaTotal">Área Total</label>
                            <input v-model="imovelAtualizado.areaTotal"
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="areaTotal" type="number" required>
                        </div>
                        <div class="col-span-2">
                            <ImageUpload @images-uploaded="onImagesUploaded" />
                        </div>
                    </div>
                    <div class="flex items-center justify-between mt-4">
                        <button
                            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit">Atualizar</button>
                        <button
                            class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            @click="imovelParaEditar = null">Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import ImageUpload from './ImageUpload.vue'; 

const imoveis = ref([]);
const search = ref('');
const imovelSelecionado = ref(null);
const imovelParaEditar = ref(null);
const imovelAtualizado = ref({});
const selectedImages = ref([]); 

const fetchImoveis = async () => {
    try {
        const response = await axios.get('/imoveis');
        imoveis.value = response.data;
    } catch (error) {
        console.error('Erro ao buscar imóveis:', error);
    }
};

const filteredImoveis = computed(() => {
    return imoveis.value.filter(imovel =>
        imovel.name.toLowerCase().includes(search.value.toLowerCase()) ||
        formatCurrency(imovel.price).includes(search.value)
    );
});

const formatCurrency = (value) => {
    const number = Number(value);
    return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

const verDetalhes = (imovel) => {
    imovelSelecionado.value = imovel;
};

const editarImovel = (imovel) => {
    imovelParaEditar.value = imovel;
    imovelAtualizado.value = { ...imovel };
};

const excluirImovel = async (id) => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user ? user.token : null;
        if (!token) {
            alert('Não autenticado. Por favor, faça login para continuar.');
            return;
        }
        if (window.confirm('Tem certeza que deseja excluir este imóvel?')) {
            await axios.delete(`/imoveis/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            imoveis.value = imoveis.value.filter(imovel => imovel.id !== id);
            alert('Imóvel excluído com sucesso.');
        } else {
            alert('Exclusão cancelada.');
        }
    } catch (error) {
        console.error('Erro ao excluir imóvel:', error);
    }
};

const onImagesUploaded = (files) => {
    selectedImages.value = files;
};

const atualizarImovel = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        const token = user ? user.token : null;
        if (!token) {
            alert('Não autenticado. Por favor, faça login para continuar.');
            return;
        }

        await axios.put(`/imoveis/${imovelAtualizado.value.id}`, imovelAtualizado.value, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (selectedImages.value.length > 0) {
            const imageForm = new FormData();
            selectedImages.value.forEach(file => {
                imageForm.append('images', file);
            });
            await axios.put(`/images/${imovelAtualizado.value.id}`, imageForm, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });
        }

        alert('Atualização do imóvel concluída com sucesso.');
        imovelParaEditar.value = null;
        fetchImoveis();
    } catch (error) {
        console.error('Erro ao atualizar imóvel:', error);
        alert('Falha ao atualizar o imóvel. Tente novamente.');
    }
};

onMounted(fetchImoveis);
</script>
