<template>
    <div class="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
        <h1
            class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-yellow-500 to-yellow-500 text-transparent bg-clip-text">
            Cadastro de Leads</h1>
        <br />

        <form @submit.prevent="createLead" class="space-y-6">
            <div>
                <label for="name" class="block text-sm font-medium text-gray-700">Nome Completo</label>
                <input id="name" type="text" v-model="lead.name" required placeholder="Digite seu nome completo"
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
            </div>

            <div>
                <label for="telephone" class="block text-sm font-medium text-gray-700">Telefone</label>
                <input id="telephone" type="text" v-model="lead.telephone" required placeholder="Digite seu telefone"
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
            </div>

            <div>
                <label for="message" class="block text-sm font-medium text-gray-700">Mensagem</label>
                <textarea id="message" v-model="lead.message" required placeholder="Digite sua mensagem"
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"></textarea>
            </div>
            <div>
                <label for="type" class="block text-sm font-medium text-gray-700">Selecione o tipo de lead</label>
                <select id="type" v-model="lead.type" required
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option disabled value="">Selecione o tipo de lead</option>
                    <option value="Google">Google</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Amigo/Família">Amigo/Família</option>
                    <option value="Outro">Outro</option>
                </select>
            </div>
            <div>
                <label for="type1" class="block text-sm font-medium text-gray-700">Selecione o modelo de lead</label>
                <select id="type1" v-model="lead.type1"
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option disabled value="">Selecione o modelo de lead</option>
                    <option value="Compra">Compra</option>
                    <option value="Venda">Venda</option>
                    <option value="Permuta">Permuta</option>
                </select>
            </div>
            <div>
                <label for="type2" class="block text-sm font-medium text-gray-700">Selecione o status do lead</label>
                <select id="type2" v-model="lead.type2"
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option disabled value="">Selecione o status do lead</option>
                    <option value="Frio">Frio</option>
                    <option value="Quente">Quente</option>
                    <option value="Morno">Morno</option>
                </select>
            </div>
            <div class="flex justify-end">
                <button type="submit"
                    class="px-6 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Cadastrar
                </button>
            </div>
        </form>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const router = useRouter();
const lead = ref({ name: '', telephone: '', message: '', type: '', type1: '', type2: '' });
const loading = ref(false);
const error = ref(null);
const success = ref(false);

async function createLead() {
    loading.value = true;
    error.value = null;
    success.value = false;

    const user = JSON.parse(localStorage.getItem('user'));
    const token = user ? user.token : null;

    if (!token) {
        error.value = "Não autenticado. Por favor, faça login para continuar.";
        loading.value = false;
        return;
    }

    try {
        await axios.post('/client', lead.value, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        success.value = true;
        setTimeout(() => {
            router.push('/dashboard');
        }, 2000);
    } catch (e) {
        error.value = "Não foi possível criar o lead.";
    } finally {
        loading.value = false;
    }
}
</script>

<style scoped>
.create-lead-page {
    max-width: 800px;
    margin: auto;
}
</style>
