<template>
  <div class="flex flex-wrap justify-center">
    <div v-for="imovel in sortedImoveis" :key="imovel.id"
      class="max-w-sm w-full md:w-1/3 xl:w-1/4 rounded-lg overflow-hidden shadow-lg m-4 bg-white h-full flex flex-col">
      <div v-if="imovel.images && imovel.images.length > 0" class="flex-shrink-0">
        <carousel :itemsToShow="1" :navigationEnabled="true" :paginationEnabled="true" :loop="true">
          <slide v-for="img in imovel.images" :key="img.id">
            <img :src="getImageUrl(imovel.id, img.url)" :alt="`Image of ${imovel.name}`" class="w-full object-cover"
              style="height: 200px;" />
          </slide>
          <template #addons>
            <navigation />
            <pagination />
          </template>
        </carousel>
      </div>
      <div class="p-6 flex-grow flex flex-col justify-between">
        <div class="font-bold text-xl text-gray-800 name">{{ imovel.name }}</div>
        <p class="text-gray-700 text-base location">{{ imovel.location }}</p>
        <div class="flex flex-wrap items-center justify-center text-sm space-x-4 my-2">
          <div class="flex items-center">
            <i class="fas fa-bed text-gray-500"></i>
            <span class="ml-1">{{ imovel.bedrooms }}</span>
          </div>
          <div class="flex items-center">
            <i class="fas fa-bath text-gray-500"></i>
            <span class="ml-1">{{ imovel.bathrooms }}</span>
          </div>
          <div class="flex items-center">
            <i class="fas fa-ruler text-gray-500"></i>
            <span class="ml-1">{{ imovel.area }} m²</span>
          </div>
          <div class="flex items-center">
            <i class="fas fa-ruler-combined text-gray-500"></i>
            <span class="ml-1">{{ imovel.areaTotal }} m²</span>
          </div>
        </div>
        <div class="mt-auto">
          <div class="flex justify-between items-center px-6 pt-4 pb-2">
            <span class="bg-green-500 rounded-full px-4 py-2 text-sm font-semibold text-white">
              {{ formatCurrency(imovel.price) }}
            </span>
            <a :href="`/imovel/${imovel.id}`"
              class="bg-yellow-500 hover:bg-yellow-600 shadow-md rounded-full px-4 py-2 text-sm font-semibold text-white transition duration-300 ease-in-out"
              style="min-width: 100px; white-space: nowrap;">
              Saiba Mais
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from 'vue';
import axios from 'axios';
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

const props = defineProps({
  filters: {
    type: Object,
    required: true,
  },
});

const imoveis = ref([]);

onMounted(async () => {
  try {
    const response = await axios.get('/imoveis');
    imoveis.value = response.data.map(imovel => ({
      ...imovel,
      images: imovel.images.map(image => ({ id: image.id, url: image.url }))
    }));
  } catch (error) {
    console.error(error);
  }
});

const filteredImoveis = computed(() => {
  return imoveis.value.filter(imovel => {
    const matchesSearch = imovel.location.toLowerCase().includes(props.filters.search.toLowerCase()) || imovel.name.toLowerCase().includes(props.filters.search.toLowerCase());
    const matchesType = props.filters.type ? imovel.type.toLowerCase().replace(/ /g, '') === props.filters.type : true;
    return matchesSearch && matchesType;
  });
});

const sortedImoveis = computed(() => {
  return [...filteredImoveis.value].sort((a, b) => a.price - b.price);
});

const getImageUrl = (imovelId, imageUrl) => {
  const baseURL = 'https://alvaromottaback-production.up.railway.app/';
  const url = imageUrl.startsWith('/') ? imageUrl.substring(1) : imageUrl;
  return `${baseURL}${url}`;
};

const formatCurrency = (value) => {
  const number = Number(value);
  return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

watch(() => props.filters, () => {
});
</script>

<style scoped>
.name {
  min-height: 3rem;
  height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.location {
  min-height: 3rem;
  height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
