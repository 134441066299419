<template>
  <div class="flex flex-row min-h-screen">
    <div class="bg-gray-200 text-gray-800 w-64 space-y-6 py-7 px-2">
      <div class="text-lg px-4 py-2 flex items-center space-x-3">
        <img class="h-12 w-auto"
          src="https://i.ibb.co/RpzLpPm/Whats-App-Image-2024-05-07-at-22-14-13-removebg-preview.png" alt="Logo">
        <span>Menu</span>
      </div>
      <router-link to="/dashboard" class="flex items-center px-4 py-2 hover:bg-white">
        <HomeIcon class="h-6 w-6 mr-2" />
        Dashboard
      </router-link>
      <router-link to="/dashboard/create" class="flex items-center px-4 py-2  hover:bg-white">
        <PlusCircleIcon class="h-6 w-6 mr-2" />
        Cadastrar Imóvel
      </router-link>
      <router-link to="/dashboard/view" class="flex items-center px-4 py-2 hover:bg-white">
        <SearchIcon class="h-6 w-6 mr-2" />
        Visualizar Imóveis
      </router-link>
      <router-link to="/dashboard/client" class="flex items-center px-4 py-2  hover:bg-white">
        <InboxIcon class="h-6 w-6 mr-2" />
        Cadastro de Leads
      </router-link>
      <router-link to="/dashboard/clientview" class="flex items-center px-4 py-2 hover:bg-white ">
        <InboxIcon class="h-6 w-6 mr-2" />
        Leads Cadastrados
      </router-link>
      <router-link to="/dashboard/leads" class="flex items-center px-4 py-2 hover:bg-white">
        <InboxIcon class="h-6 w-6 mr-2" />
        Leads Externos
      </router-link>
      <router-link to="/dashboard/register" class="flex items-center px-4 py-2  hover:bg-white">
        <UserAddIcon class="h-6 w-6 mr-2" />
        Cadastrar Usuário
      </router-link>
      <router-link to="/dashboard/users" class="flex items-center px-4 py-2 hover:bg-white">
        <UserIcon class="h-6 w-6 mr-2" />
        Gerenciar Usuários
      </router-link>
    </div>
    <div class="flex flex-col flex-grow">
      <div
        class="bg-gradient-to-r from-gray-500 to-gray-700 text-white p-4 text-lg flex justify-between items-center shadow-lg">
        <img src="https://i.ibb.co/RpzLpPm/Whats-App-Image-2024-05-07-at-22-14-13-removebg-preview.png" alt="Logo"
          class="h-8 w-auto mr-2">
        <h1 class="font-semibold flex-grow">Painel Administrador</h1>
        <div class="flex items-center">
          <router-link to="/dashboard/create">
            <button class="ml-4 bg-gray-800 hover:bg-gray-900 p-2 rounded-full shadow focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                class="h-6 w-6 text-white">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12h18m-9 9V3m9 9H3" />
              </svg>
            </button>
          </router-link>
          <button @click="logout" class="ml-4 bg-gray-800 hover:bg-gray-900 p-2 rounded-full shadow focus:outline-none">
            <LogoutIcon class="h-6 w-6 text-white" />
          </button>
        </div>
      </div>
      <div class="flex-grow p-4">
        <router-view />
      </div>
      <footer class="bg-gray-800 text-white text-center p-4 text-sm">
        © 2024 Alvaro Motta Imóveis. Todos os direitos reservados.
      </footer>
    </div>
  </div>
</template>

<script setup>
import { HomeIcon, PlusCircleIcon, LogoutIcon, UserAddIcon, UserIcon, SearchIcon, InboxIcon } from '@heroicons/vue/outline'
import { useRouter } from 'vue-router'
import authService from '@/service/authService';

const router = useRouter();

function logout() {
  authService.logout();
  router.push('/login');
}
</script>
