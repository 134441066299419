import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '../views/LandingPage.vue';
import LoginPage from '../views/LoginPage.vue';
import DashboardPage from '../views/DashboardPage.vue';
import DashboardPag from '@/components/DashboardPag.vue';
import CreateImovel from '../components/CreateImovel.vue';
import ViewImoveis from '../components/ViewImoveis.vue';
import ViewUsers from '@/components/ViewUsers.vue';
import CreateUsers from '@/components/CreateUsers.vue';
import ImovelDetalhes from '@/views/ImovelDetalhes.vue';
import LeadsPage from '@/components/LeadsPage.vue';
import CreateClient from '@/components/CreateClient.vue';
import ViewClient from '@/components/ViewClient.vue';
import AboutPage from '@/views/AboutPage.vue';
import ContactPage from '@/views/ContactPage.vue';


const routes = [
    {
        path: '/',
        name: 'Home',
        component: LandingPage
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage
    },
    {
        path: '/imovel/:id',
        name: 'ImovelDetails',
        component: ImovelDetalhes
    },
    {
        path: '/sobre',
        name: 'Sobre',
        component: AboutPage
    },
    {
        path: '/contato',
        name: 'ContatoPage',
        component: ContactPage
    },
    {
        path: '/dashboard',
        name: 'DashboardPage',
        component: DashboardPage,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'create',
                component: CreateImovel,
                name: 'CreateImovel'
            },
            {
                path: 'view',
                component: ViewImoveis,
                name: 'ViewImoveis'
            },
            {
                path: '',
                component: DashboardPag,
                name: 'Dashboard'

            },
            {
                path: 'users',
                component: ViewUsers,
                name: 'ViewUsers'
            },
            {
                path: 'register',
                component: CreateUsers,
                name: 'CreateUsers'
            },
            {
                path: 'leads',
                component: LeadsPage,
                name: 'LeadsPage'
            },
            {
                path: 'client',
                component: CreateClient,
                name: 'CreateClient'
            },
            {
                path: 'clientview',
                component: ViewClient,
                name: 'ViewClient'
            }

        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const currentUser = localStorage.getItem('user');

    if (requiresAuth && !currentUser) {
        next({
            path: '/login',
            query: { redirect: to.fullPath }
        });
    } else {
        next();
    }
});


export default router;
