<template>
  <nav class="bg-gradient-to-r from-gray-500 via-gray-700 to-gray-800 shadow-lg fixed w-full top-0 left-0 z-50">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <router-link to="/" class="flex items-center">
          <img class="h-20 w-auto"
            src="https://i.ibb.co/RpzLpPm/Whats-App-Image-2024-05-07-at-22-14-13-removebg-preview.png"
            alt="Alvaro Motta Imóveis">
          <h1 class="text-white text-xl font-bold ml-3">Alvaro Motta Imóveis</h1>
        </router-link>
        <div class="hidden sm:block sm:ml-6">
          <div class="flex space-x-4">
            <a href="/"
              class="text-amber-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
              <i class="fas fa-home"></i> Inicio
            </a>
            <a href="/sobre"
              class="text-amber-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
              <i class="fas fa-info-circle"></i> Sobre
            </a>
            <a href="/contato"
              class="text-amber-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
              <i class="fas fa-envelope"></i> Fale Conosco
            </a>
            <a href="https://wa.me/77988059201?text=Olá!%20Gostaria%20de%20mais%20informações%20sobre%20venda."
              class="text-amber-300 bg-black hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
              <i class="fas fa-dollar-sign"></i> Vender Imóvel
            </a>
            <a href="https://wa.me/77988059201?text=Olá!%20Gostaria%20de%20mais%20informações%20sobre%20permuta."
              class="text-amber-300 bg-black border-radius-black hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
              <i class="fas fa-exchange-alt"></i> Fazer Permuta
            </a>
          </div>
        </div>
        <div class="sm:hidden">
          <button @click="open = !open" type="button"
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white">
            <span class="sr-only">Open main menu</span>
            <svg v-show="!open" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
              stroke-width="2" class="h-6 w-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <svg v-show="open" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
              stroke-width="2" class="h-6 w-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div v-show="open" class="sm:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <a href="/"
          class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
          <i class="fas fa-home"></i> Inicio
        </a>
        <a href="/sobre"
          class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
          <i class="fas fa-info-circle"></i> Sobre
        </a>
        <a href="/contato"
          class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
          <i class="fas fa-envelope"></i> Fale Conosco
        </a>
        <a href="https://wa.me/77988059201?text=Olá!%20Gostaria%20de%20mais%20informações%20sobre%20venda."
          class="text-gray-300 bg-black hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
          <i class="fas fa-dollar-sign"></i> Vender Imóvel
        </a>
        <a href="https://wa.me/77988059201?text=Olá!%20Gostaria%20de%20mais%20informações%20sobre%20permuta."
          class="text-gray-300 bg-black hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
          <i class="fas fa-exchange-alt"></i> Fazer Permuta
        </a>
      </div>
    </div>
  </nav>
</template>
<script setup>
import { ref } from 'vue';
const open = ref(false);
</script>
