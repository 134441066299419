<template>
  <div>
    <label for="image" class="block text-sm font-medium text-gray-700">Images</label>
    <input id="image" type="file" multiple @change="onFileChange"
      class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
    <div class="mt-4 grid grid-cols-3 gap-4">
      <img v-for="preview in imagePreviews" :src="preview" :key="preview" alt="Preview"
        class="w-32 h-32 object-cover rounded-md">
    </div>
  </div>
</template>
  
<script>
import { ref } from 'vue';

export default {
  setup(props, { emit }) {
    const imagePreviews = ref([]);
    const selectedFiles = ref([]);

    const onFileChange = (event) => {
      const files = Array.from(event.target.files);
      if (files.every(file => file.type.startsWith('image/'))) {
        imagePreviews.value = files.map(file => URL.createObjectURL(file));
        selectedFiles.value = files;
        emit('images-uploaded', files);
      } else {
        alert('Por favor, escolha imagens válidas.');
        imagePreviews.value = [];
      }
    };

    return {
      imagePreviews,
      onFileChange
    };
  }
};
</script>
