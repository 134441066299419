<template>
    <div class="p-6">
        <h1
            class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-yellow-500 to-yellow-500 text-transparent bg-clip-text">
            Dashboard de Imóveis</h1>
        <br />
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
            <div class="bg-white p-4 shadow rounded-lg flex items-center" v-for="stat in stats" :key="stat.title">
                <i :class="stat.icon" class="text-3xl text-gray-500 mr-4"></i>
                <div>
                    <p class="text-sm text-gray-500">{{ stat.title }}</p>
                    <p class="text-xl font-semibold">{{ stat.value }}</p>
                </div>
            </div>
        </div>
        <div class="bg-white p-4 shadow rounded-lg">
            <div ref="chartRef"></div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, reactive } from 'vue';
import axios from 'axios';
import ApexCharts from 'apexcharts';

const chartRef = ref(null);
const stats = reactive([]);
const chartData = reactive({
    series: [],
    options: {
        chart: {
            type: 'line',
            height: 350
        },
        xaxis: {}
    }
});

const user = JSON.parse(localStorage.getItem('user'));
const token = user ? user.token : null;

const authHeaders = {
    headers: {
        'Authorization': `Bearer ${token}`
    }
};

onMounted(async () => {
    try {
        const [imoveisResponse, clientResponse, contactResponse] = await Promise.all([
            axios.get('/imoveis'),
            axios.get('/client', authHeaders),
            axios.get('/contact', authHeaders)
        ]);

        const imoveisData = processImoveisData(imoveisResponse.data);
        const clientData = clientResponse.data;
        const contactData = contactResponse.data;

        stats.push({ title: 'Total de Imóveis', value: imoveisData.length, icon: 'fas fa-building' });
        stats.push({ title: 'Valor Médio', value: calculateAveragePrice(imoveisData), icon: 'fas fa-dollar-sign' });
        stats.push({ title: 'Leads Cadastrados', value: clientData.length, icon: 'fas fa-user' });
        stats.push({ title: 'Leads Externos', value: contactData.length, icon: 'fas fa-users' });

        updateChart(imoveisData);
    } catch (error) {
        console.error(error);
    }
});

function processImoveisData(data) {
    return data.map(imovel => ({
        ...imovel,
        price: parseFloat(imovel.price)
    }));
}

function updateChart(imoveisData) {
    const seriesData = {
        name: 'Preço dos Imóveis',
        data: imoveisData.map(imovel => imovel.price)
    };

    const xaxisConfig = {
        categories: imoveisData.map(imovel => new Date(imovel.createdAt).toLocaleDateString('pt-BR'))
    };
    chartData.series = [seriesData];
    chartData.options.xaxis = xaxisConfig;

    const chart = new ApexCharts(chartRef.value, {
        ...chartData.options,
        series: chartData.series
    });

    chart.render();
}

function calculateAveragePrice(imoveisData) {
    const total = imoveisData.reduce((acc, curr) => acc + (isNaN(curr.price) ? 0 : curr.price), 0);
    const count = imoveisData.reduce((acc, curr) => acc + (isNaN(curr.price) ? 0 : 1), 0);
    return count > 0 ? (total / count).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00';
}
</script>