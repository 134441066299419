<template>
    <div class="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
        <h1
            class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-yellow-500 to-yellow-500 text-transparent bg-clip-text">
            Cadastro de Usuário</h1>
        <br />

        <form @submit.prevent="submitForm" class="space-y-6">
            <div>
                <label for="name" class="block text-sm font-medium text-gray-700">Nome Completo</label>
                <input id="name" type="text" v-model="user.name" required placeholder="Digite seu nome completo"
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
            </div>

            <div>
                <label for="username" class="block text-sm font-medium text-gray-700">Nome de Usuário</label>
                <input id="username" type="text" v-model="user.username" required
                    placeholder="Nome de usuário para login"
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
            </div>

            <div>
                <label for="email" class="block text-sm font-medium text-gray-700">E-mail</label>
                <input id="email" type="email" v-model="user.email" required placeholder="Digite seu e-mail"
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
            </div>

            <div>
                <label for="password" class="block text-sm font-medium text-gray-700">Senha</label>
                <input id="password" type="password" v-model="user.password" required
                    placeholder="Escolha uma senha segura"
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
            </div>

            <div class="flex justify-end">
                <button type="submit"
                    class="px-6 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                    Cadastrar
                </button>
            </div>
        </form>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';

const user = ref({
    name: '',
    username: '',
    email: '',
    password: ''
});
const loading = ref(false);

const submitForm = async () => {
    if (loading.value) return;
    loading.value = true;
    const userData = JSON.parse(localStorage.getItem('user'));
    const token = userData ? userData.token : null;
    if (!token) {
        alert('Não autenticado. Por favor, faça login para continuar.');
        loading.value = false;
        return;
    }
    try {
        await axios.post('/users', user.value, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        });
        alert('Usuário cadastrado com sucesso!');
        user.value = { name: '', username: '', email: '', password: '' };
    } catch (error) {
        if (error.response) {
            alert('Erro ao cadastrar usuário: ' + error.response.data.message);
        } else {
            alert('Erro ao cadastrar usuário. Tente novamente mais tarde.');
        }
    }
    loading.value = false;
};
</script>
