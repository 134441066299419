<template>
  <div class="relative flex justify-center items-center min-h-screen p-4 bg-black overflow-hidden">
    <div class="absolute inset-0 z-0 pointer-events-none">
      <canvas id="particles"></canvas>
    </div>

    <div class="absolute inset-0 bg-gradient-to-br from-black via-yellow-800 to-yellow-600 opacity-80"></div>

    <div
      class="relative z-10 p-8 bg-black/70 border border-yellow-700 rounded-3xl shadow-lg w-full max-w-md backdrop-blur-lg">
      <div class="text-center mb-10">
        <img src="https://i.ibb.co/RpzLpPm/Whats-App-Image-2024-05-07-at-22-14-13-removebg-preview.png"
          alt="Company Logo" class="mx-auto h-20 w-auto">
        <h1 class="text-3xl font-extrabold text-yellow-400 mt-6 tracking-wide glow">Acesso Restrito</h1>
        <p class="text-sm text-yellow-300">Entre com seus dados para continuar</p>
      </div>
      <form @submit.prevent="login" class="space-y-6">
        <div>
          <label for="username" class="block text-sm font-medium text-yellow-300 mb-2">Usuário</label>
          <input id="username" v-model="username" type="text" placeholder="Digite seu usuário" required
            class="w-full px-4 py-3 bg-transparent border border-yellow-600 rounded-lg text-yellow-100 text-sm placeholder-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent transition-all duration-300 ease-in-out glow-input" />
        </div>
        <div>
          <label for="password" class="block text-sm font-medium text-yellow-300 mb-2">Senha</label>
          <div class="relative">
            <input id="password" v-model="password" :type="passwordVisible ? 'text' : 'password'"
              placeholder="Digite sua senha" required
              class="w-full px-4 py-3 bg-transparent border border-yellow-600 rounded-lg text-yellow-100 text-sm placeholder-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent transition-all duration-300 ease-in-out glow-input" />
            <button type="button" @click="togglePasswordVisibility"
              class="absolute inset-y-0 right-4 text-yellow-400 focus:outline-none text-sm">
              {{ passwordVisible ? 'Ocultar' : 'Mostrar' }}
            </button>
          </div>
        </div>
        <button type="submit" :disabled="isButtonDisabled"
          class="w-full bg-gradient-to-r from-yellow-600 to-yellow-800 hover:from-yellow-500 hover:to-yellow-700 text-white font-semibold py-3 rounded-lg shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-neon disabled:opacity-50">
          Entrar
        </button>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import authService from '@/service/authService';
import { useRouter } from 'vue-router';

const username = ref('');
const password = ref('');
const passwordVisible = ref(false);
const router = useRouter();

const isButtonDisabled = computed(() => {
  return username.value.trim() === '' || password.value.trim() === '';
});

const togglePasswordVisibility = () => {
  passwordVisible.value = !passwordVisible.value;
};

const login = async () => {
  try {
    const response = await authService.login({ username: username.value, password: password.value });
    if (response.success) {
      localStorage.setItem('user', JSON.stringify({ token: response.token }));
      router.push('/dashboard');
    } else {
      alert('Login falhou: ' + response.message);
    }
  } catch (error) {
    console.error('Erro no login:', error);
    alert('Erro no login, tente novamente.');
  }
};

const initParticles = () => {
  const canvas = document.getElementById('particles');
  const ctx = canvas.getContext('2d');

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  const particles = Array(100)
    .fill()
    .map(() => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      radius: Math.random() * 3 + 1,
      dx: Math.random() * 2 - 1,
      dy: Math.random() * 2 - 1,
    }));

  const animate = () => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    particles.forEach((particle) => {
      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
      ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
      ctx.fill();
      particle.x += particle.dx;
      particle.y += particle.dy;
      if (particle.x < 0 || particle.x > canvas.width) particle.dx *= -1;
      if (particle.y < 0 || particle.y > canvas.height) particle.dy *= -1;
    });
    requestAnimationFrame(animate);
  };

  animate();
};

onMounted(() => {
  initParticles();
});
</script>

<style scoped>
body {
  font-family: 'Inter', sans-serif;
}

.glow {
  text-shadow: 0 0 8px rgba(255, 255, 0, 0.8), 0 0 15px rgba(255, 255, 0, 0.6);
}

.glow-input:focus {
  box-shadow: 0 0 5px rgba(255, 255, 0, 0.5), 0 0 10px rgba(255, 255, 0, 0.3);
}

.hover\:shadow-neon:hover {
  box-shadow: 0 0 10px rgba(255, 255, 0, 0.8), 0 0 20px rgba(255, 255, 0, 0.6);
}
</style>
