<template>
    <div class="flex flex-col min-h-screen">
        <Header />
        <div class="flex-grow">
            <FeatureSection class="mt-14" @apply-filters="applyFilters" />
            <ImoveisList :filters="filters" />
        </div>
        <Footer />
        <WhatsappLink />
    </div>
</template>

<script setup>
import { ref } from 'vue';
import Header from '@/components/PageHeader.vue';
import FeatureSection from '@/components/FeatureSection.vue';
import Footer from '@/components/PageFooter.vue';
import ImoveisList from '@/components/ImoveisList.vue';
import WhatsappLink from '@/components/WhatsappLink.vue';

const filters = ref({ search: '', type: '' });

const applyFilters = (newFilters) => {
    filters.value = newFilters;
};
</script>
