<template>
  <div class="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
    <h1
      class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-yellow-500 to-yellow-500 text-transparent bg-clip-text">
      Cadastro de Imóvel</h1>
    <br />
    <form @submit.prevent="submitForm" class="space-y-6">
      <div>
        <label for="name" class="block text-sm font-medium text-gray-700">Nome do Imovel</label>
        <input id="name" type="text" v-model="imovel.name" required placeholder="Nome do Imovel"
          class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
      </div>

      <div>
        <label for="description" class="block text-sm font-medium text-gray-700">Descrição</label>
        <textarea id="description" v-model="imovel.description" rows="4" required placeholder="Descreva o imóvel"
          class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"></textarea>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label for="price" class="block text-sm font-medium text-gray-700">Preço</label>
          <input id="price" type="number" v-model="imovel.price" required placeholder="Preço do Imovel"
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
        </div>

        <div>
          <label for="location" class="block text-sm font-medium text-gray-700">Localização</label>
          <input id="location" type="text" v-model="imovel.location" required placeholder="Localização do Imovel"
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
        </div>
        <div>
          <label for="city" class="block text-sm font-medium text-gray-700">Cidade</label>
          <input id="city" type="text" v-model="imovel.city" required placeholder="Cidade do Imovel"
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
        </div>

      </div>

      <ImageUpload v-on:images-uploaded="onImagesUploaded" />

      <div>
        <label for="link" class="block text-sm font-medium text-gray-700">Link do vídeo</label>
        <input id="link" type="text" v-model="imovel.link" required placeholder="Link do vídeo do imóvel"
          class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label for="type" class="block text-sm font-medium text-gray-700">Tipo de Imóvel</label>
          <select id="type" v-model="imovel.type" required
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
            <option disabled value="">Selecione o tipo de imóvel</option>
            <option v-for="(typeOption, index) in typeOptions" :key="index" :value="typeOption">{{ typeOption }}
            </option>
          </select>
        </div>
        <div>
          <label for="bedrooms" class="block text-sm font-medium text-gray-700">Quartos</label>
          <input id="bedrooms" type="number" v-model="imovel.bedrooms" required placeholder="Número de Quartos"
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
        </div>

        <div>
          <label for="bathrooms" class="block text-sm font-medium text-gray-700">Banheiros</label>
          <input id="bathrooms" type="number" v-model="imovel.bathrooms" required placeholder="Número de Banheiros"
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
        </div>

        <div>
          <label for="suites" class="block text-sm font-medium text-gray-700">Suítes</label>
          <input id="suites" type="number" v-model="imovel.suites" required placeholder="Número de Suítes"
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
        </div>

        <div>
          <label for="area" class="block text-sm font-medium text-gray-700">Área (m²)</label>
          <input id="area" type="number" v-model="imovel.area" required placeholder="Área em metros quadrados"
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
        </div>

        <div>
          <label for="areaTotal" class="block text-sm font-medium text-gray-700">Área Total (m²)</label>
          <input id="areaTotal" type="number" v-model="imovel.areaTotal" required
            placeholder="Área Total em metros quadrados"
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
        </div>
      </div>
      <div class="flex justify-end">
        <button type="submit"
          class="px-6 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
          Enviar
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import ImageUpload from './ImageUpload.vue';

const imovel = ref({
  name: '',
  description: '',
  price: '',
  location: '',
  city: '',
  link: '',
  type: '',
  bedrooms: 0,
  bathrooms: 0,
  suites: 0,
  area: 0,
  areaTotal: 0,
  createdAt: new Date().toISOString(),
  images: []
});

const onImagesUploaded = (files) => {
  imovel.value.images = files;
};
const typeOptions = ['Apartamento', 'Casa', 'Casa em Condominio', 'Terreno', 'Estúdio', 'Sitio', 'Ponto Comercial', 'Fazenda', 'Loja', 'Sala', 'Outro'];
const submitForm = async () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user ? user.token : null;
  if (!token) {
    alert('Não autenticado. Por favor, faça login para continuar.');
    return;
  }

  const imovelData = {
    name: imovel.value.name,
    description: imovel.value.description,
    price: imovel.value.price,
    location: imovel.value.location,
    city: imovel.value.city,
    link: imovel.value.link,
    type: imovel.value.type,
    bedrooms: imovel.value.bedrooms,
    bathrooms: imovel.value.bathrooms,
    suites: imovel.value.suites,
    area: imovel.value.area,
    areaTotal: imovel.value.areaTotal,
    createdAt: imovel.value.createdAt
  };

  try {
    const response = await axios.post('/imoveis', imovelData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (response.data && imovel.value.images.length > 0) {
      const imageForm = new FormData();
      imovel.value.images.forEach(file => {
        imageForm.append('images', file); 
      });
      await axios.post(`/images/${response.data.id}`, imageForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      });
    }
    alert('Imóvel criado com sucesso!');
    resetForm();
    location.reload(); 
  } catch (error) {
    alert('Erro ao criar imóvel.');
  }
};

const resetForm = () => {
  imovel.value = {
    name: '',
    description: '',
    price: '',
    location: '',
    city: '',
    link: '',
    type: '',
    bedrooms: 0,
    bathrooms: 0,
    suites: 0,
    area: 0,
    areaTotal: 0,
    createdAt: new Date().toISOString(),
    images: []
  };
};

</script>