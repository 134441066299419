<template>
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1
            class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-yellow-500 to-yellow-500 text-transparent bg-clip-text">
            Visualizar Usuários
        </h1>
        <br />
        <input v-model="search" class="mb-4 p-2 w-full border rounded" placeholder="Pesquisar usuário...">
        <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
                <tr>
                    <th scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nome
                    </th>
                    <th scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">E-mail
                    </th>
                    <th scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ações
                    </th>
                </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="user in filteredUsers" :key="user.id">
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{{ user.name }}</td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{{ user.email }}</td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button @click="editUser(user)"
                            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">Editar</button>
                        <button @click="confirmDelete(user.id)"
                            class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Deletar</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-if="showDeleteConfirmation"
            class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div class="bg-white p-4 rounded-lg shadow-lg max-w-sm">
                <h2 class="text-lg font-bold mb-4">Confirmar Exclusão</h2>
                <p>Deseja realmente excluir este usuário?</p>
                <div class="mt-4 flex justify-end space-x-2">
                    <button @click="deleteUser"
                        class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Confirmar</button>
                    <button @click="showDeleteConfirmation = false"
                        class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cancelar</button>
                </div>
            </div>
        </div>

        <div v-if="userToEdit" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div class="bg-white p-4 rounded-lg shadow-lg w-full max-w-2xl">
                <h2 class="text-lg font-bold mb-4">Editar Usuário</h2>
                <form @submit.prevent="updateUser">
                    <label class="block text-gray-700 text-sm font-bold mb-2">Nome</label>
                    <input v-model="userToEdit.name" type="text" class="mb-4 p-2 w-full border rounded" required>
                    <label class="block text-gray-700 text-sm font-bold mb-2">E-mail</label>
                    <input v-model="userToEdit.email" type="email" class="mb-4 p-2 w-full border rounded" required>
                    <label class="block text-gray-700 text-sm font-bold mb-2">Senha</label>
                    <input v-model="userToEdit.password" type="password" class="mb-4 p-2 w-full border rounded"
                        required>
                    <div class="flex justify-end mt-4">
                        <button type="submit"
                            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">Atualizar</button>
                        <button @click="userToEdit = null"
                            class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

const users = ref([]);
const search = ref('');
const showDeleteConfirmation = ref(false);
const userToEdit = ref(null);
const userIdToDelete = ref(null);

const fetchUsers = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user ? user.token : null;
    if (!token) {
        alert('Não autenticado. Por favor, faça login para continuar.');
        return;
    }
    try {
        const response = await axios.get(`/users?timestamp=${new Date().getTime()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Cache-Control': 'no-cache'
            }
        });
        users.value = response.data;
    } catch (error) {
        console.error('Failed to fetch users:', error);
    }
};

const editUser = (user) => {
    userToEdit.value = { ...user, password: '' }; // Define password as an empty string
};

const updateUser = async () => {
    const token = JSON.parse(localStorage.getItem('user'))?.token;
    if (!token) {
        alert('Não autenticado. Por favor, faça login para continuar.');
        return;
    }
    try {
        const userId = userToEdit.value.id;
        await axios.put(`https://alvaromottaback-production.up.railway.app/users/${userId}`, userToEdit.value, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        userToEdit.value = null;
        fetchUsers();
    } catch (error) {
        console.error('Erro ao atualizar o usuário:', error);
    }
};

const confirmDelete = (id) => {
    userIdToDelete.value = id;
    showDeleteConfirmation.value = true;
};

const deleteUser = async () => {
    const token = JSON.parse(localStorage.getItem('user'))?.token;
    if (!token) {
        alert('Não autenticado. Por favor, faça login para continuar.');
        return;
    }
    try {
        await axios.delete(`https://alvaromottaback-production.up.railway.app/users/${userIdToDelete.value}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        showDeleteConfirmation.value = false;
        fetchUsers();
    } catch (error) {
        console.error('Erro ao excluir o usuário:', error);
    }
};

const filteredUsers = computed(() => {
    return users.value.filter(user =>
        user.name.toLowerCase().includes(search.value.toLowerCase()) ||
        user.email.toLowerCase().includes(search.value.toLowerCase())
    );
});

onMounted(fetchUsers);
</script>


