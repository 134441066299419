import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/index.js'
import '../src/css/main.css'
import axios from 'axios';

axios.defaults.baseURL = 'https://alvaromottaback-production.up.railway.app';
axios.interceptors.request.use(
    config => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.accessToken) {
            config.headers.Authorization = 'Bearer ' + user.accessToken;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

createApp(App)
    .use(router)
    .mount('#app')
