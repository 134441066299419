<template>
    <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1
            class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-yellow-500 to-yellow-500 text-transparent bg-clip-text">
            Leads Externos
        </h1>
        <br />
        <div class="flex items-center justify-between mb-4">
            <button @click="generatePDFAll"
                class="px-6 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-gray-700 bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2">
                Gerar PDF
            </button>
            <input v-model="search" type="text" placeholder="Pesquisar leads..."
                class="flex-grow p-3 border rounded shadow placeholder-gray-400 focus:ring-2 focus:ring-blue-500 transition duration-300">
        </div>

        <div v-if="loading" class="text-center text-lg">
            Carregando leads...
        </div>
        <div v-if="error" class="text-red-500 text-lg">
            Houve um erro ao carregar os dados: {{ error }}
        </div>
        <ul v-if="filteredLeads.length" id="leads-list" class="space-y-4">
            <li v-for="lead in filteredLeads" :key="lead.id"
                class="p-4 border border-gray-300 bg-white shadow-lg rounded-lg hover:bg-gray-50 transition duration-200">
                <div v-if="editId !== lead.id">
                    <div class="flex items-center mb-2">
                        <span
                            :class="{ 'bg-yellow-500': lead.type2 === 'Morno', 'bg-red-500': lead.type2 === 'Quente', 'bg-blue-500': lead.type2 === 'Frio' }"
                            class="inline-block mr-2 w-5 h-5 rounded-full"></span>
                        <h2 class="text-lg font-bold">{{ lead.name }}</h2>
                    </div>
                    <p class="text-gray-600"><strong>Telefone:</strong> {{ lead.telephone }}</p>
                    <p class="text-gray-600"><strong>Mensagem:</strong> {{ lead.message }}</p>
                    <p class="text-gray-600"><strong>De onde veio o lead:</strong> {{ lead.type }}</p>
                    <p class="text-gray-600"><strong>Modelo do lead:</strong> {{ lead.type1 }}</p>
                    <p class="text-gray-600"><strong>Status do lead:</strong> {{ lead.type2 }}</p>
                    <div class="flex justify-end mt-2">
                        <button @click="setEditId(lead.id)"
                            class="p-2 bg-blue-500 text-white rounded hover:bg-blue-700 mr-2">
                            Editar
                        </button>
                        <button @click="confirmDelete(lead.id)"
                            class="p-2 bg-red-500 text-white rounded hover:bg-red-700">
                            Deletar
                        </button>
                    </div>
                </div>
                <div v-else>
                    <form @submit.prevent="updateLead(lead.id)" class="space-y-4">
                        <div>
                            <label for="name" class="block text-sm font-medium text-gray-700">Nome Completo</label>
                            <input id="name" type="text" v-model="lead.name" required
                                class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        </div>
                        <div>
                            <label for="telephone" class="block text-sm font-medium text-gray-700">Telefone</label>
                            <input id="telephone" type="text" v-model="lead.telephone" required
                                class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        </div>
                        <div>
                            <label for="message" class="block text-sm font-medium text-gray-700">Mensagem</label>
                            <textarea id="message" v-model="lead.message" required
                                class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"></textarea>
                        </div>
                        <div>
                            <label for="type" class="block text-sm font-medium text-gray-700">Selecione o tipo de
                                lead</label>
                            <select id="type" v-model="lead.type" required
                                class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option disabled value="">Selecione o tipo de lead</option>
                                <option value="Google">Google</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Instagram">Instagram</option>
                                <option value="Amigo/Família">Amigo/Família</option>
                                <option value="Outro">Outro</option>
                            </select>
                        </div>
                        <div>
                            <label for="type1" class="block text-sm font-medium text-gray-700">Selecione o modelo do
                                lead</label>
                            <select id="type1" v-model="lead.type1"
                                class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option disabled value="">Selecione o modelo do lead</option>
                                <option value="Compra">Compra</option>
                                <option value="Venda">Venda</option>
                                <option value="Permuta">Permuta</option>
                            </select>
                        </div>
                        <div>
                            <label for="type2" class="block text-sm font-medium text-gray-700">Selecione o status do
                                lead</label>
                            <select id="type2" v-model="lead.type2"
                                class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option disabled value="">Selecione o status do lead</option>
                                <option value="Frio">Frio</option>
                                <option value="Quente">Quente</option>
                                <option value="Morno">Morno</option>
                            </select>
                        </div>
                        <div class="flex justify-end">
                            <button type="submit"
                                class="px-6 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Atualizar
                            </button>
                            <button @click="cancelEdit" type="button"
                                class="ml-2 px-6 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-gray-700 bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </li>
        </ul>
        <div v-if="!filteredLeads.length && !loading" class="text-gray-600 text-lg">
            Nenhum lead cadastrado.
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const leads = ref([]);
const loading = ref(false);
const error = ref(null);
const search = ref("");
const editId = ref(null);

onMounted(() => {
    fetchLeads();
});

async function fetchLeads() {
    loading.value = true;
    error.value = null;

    const user = JSON.parse(localStorage.getItem('user'));
    const token = user ? user.token : null;

    if (!token) {
        error.value = "Não autenticado. Por favor, faça login para continuar.";
        loading.value = false;
        return;
    }

    try {
        const response = await axios.get('/contact', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        leads.value = response.data;
    } catch (e) {
        error.value = "Não foi possível buscar os leads.";
        console.error(error);
    } finally {
        loading.value = false;
    }
}

function setEditId(id) {
    editId.value = id;
}

function cancelEdit() {
    editId.value = null;
}

async function updateLead(id) {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user ? user.token : null;

    if (!token) {
        error.value = "Não autenticado. Por favor, faça login para continuar.";
        return;
    }

    const lead = leads.value.find(lead => lead.id === id);

    try {
        await axios.put(`/contact/${id}`, lead, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        cancelEdit();
    } catch (e) {
        error.value = "Não foi possível atualizar o lead.";
    }
}
function confirmDelete(id) {
    if (confirm("Você tem certeza que deseja deletar este lead?")) {
        deleteLead(id);
    }
}
async function deleteLead(id) {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user ? user.token : null;

    if (!token) {
        error.value = "Não autenticado. Por favor, faça login para continuar.";
        return;
    }

    try {
        await axios.delete(`/contact/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        leads.value = leads.value.filter(lead => lead.id !== id);
    } catch (e) {
        error.value = "Não foi possível deletar o lead.";
    }
}
const generatePDFAll = async () => {
    const element = document.getElementById('leads-list');
    if (!element) {
        alert("Nenhum elemento encontrado para gerar o PDF");
        return;
    }
    element.classList.add('no-shadow');

    const canvas = await html2canvas(element, {
        scale: 1,
        width: element.scrollWidth,
        windowWidth: element.scrollWidth,
        windowHeight: element.scrollHeight,
        x: 0,
        y: window.pageYOffset,
        scrollY: -window.scrollY,
        useCORS: true,
        logging: true,
        backgroundColor: null
    });

    element.classList.remove('no-shadow');

    const data = canvas.toDataURL('image/png');

    const pdfWidth = 595.28;
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width; 

    const pdf = new jsPDF({
        orientation: canvas.width > canvas.height ? 'landscape' : 'portrait',
        unit: 'pt',
        format: [pdfWidth, pdfHeight]
    });
    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('leads-list-all.pdf');
};


const filteredLeads = computed(() => {
    return leads.value.filter(lead =>
        lead.name.toLowerCase().includes(search.value.toLowerCase()) ||
        lead.telephone.includes(search.value) ||
        lead.message.toLowerCase().includes(search.value.toLowerCase()) ||
        lead.type.toLowerCase().includes(search.value.toLowerCase()) ||
        (lead.type1 && lead.type1.toLowerCase().includes(search.value.toLowerCase())) ||
        (lead.type2 && lead.type2.toLowerCase().includes(search.value.toLowerCase()))
    );
});
</script>

<style scoped>
.leads-page {
    max-width: 800px;
    margin: auto;
}
.no-shadow * {
    box-shadow: none !important;
    background: none !important;
}
</style>

