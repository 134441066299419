<template>
  <div class="min-h-screen w-full px-4 py-8 bg-gray-100">
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
      <section class="lg:col-span-2">
        <h1
          class="block w-full text-center text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-yellow-500 to-yellow-500 text-transparent bg-clip-text">
          {{ imovel.name }}
        </h1>
        <div v-if="imovel.images && imovel.images.length > 0" class="mt-4">
          <Carousel :itemsToShow="1" :navigationEnabled="true" :paginationEnabled="true" :loop="true">
            <Slide v-for="img in imovel.images" :key="img.id">
              <img :src="getImageUrl(imovel.id, img.url)" :alt="`Image of ${imovel.name}`"
                class="w-full object-contain h-64 md:h-80 lg:h-96" @click="openModal(img)">
            </Slide>
            <template #addons>
              <Navigation />
              <Pagination />
            </template>
          </Carousel>
        </div>

        <Transition enter-active-class="ease-out duration-300" enter-from-class="opacity-0" enter-to-class="opacity-100"
          leave-active-class="ease-in duration-200" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <div v-if="showModal" class="showModal" @click="handleOverlayClick">
            <div class="modal-content bg-white p-4 w-full max-w-2xl rounded-lg" @click.stop>
              <img :src="getImageUrl(imovel.id, currentImage.url)" :alt="`Image of ${imovel.name}`"
                class="w-full h-auto max-h-screen object-contain">
              <div class="flex justify-between mt-4">
                <button @click="previousImage"
                  class="bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded">
                  Anterior
                </button>
                <button @click="nextImage" class="bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded">
                  Próximo
                </button>
              </div>
            </div>
          </div>
        </Transition>
        <div class="bg-white shadow-lg rounded-lg p-4 space-y-4 mt-6 relative z-0">
          <MapPage />
        </div>
      </section>

      <section class="lg:col-span-1">
        <div class="bg-white shadow-lg rounded-lg p-4 space-y-4">
          <h2 class="text-2xl font-bold text-gray-900">{{ imovel.name }}</h2><br />
          <div class="flex items-center space-x-4">
            <i class="fas fa-dollar-sign text-yellow-500"></i>
            <strong class="text-gray-600">{{ formatCurrency(imovel.price) }}</strong>
          </div>
          <div class="flex items-center space-x-4">
            <i class="fas fa-map-marker-alt text-yellow-500"></i>
            <strong class="text-gray-600">{{ imovel.location }}</strong>
          </div>
          <div class="space-y-1">
            <strong class="text-gray-600">Detalhes do Imóvel:</strong>
            <div class="grid grid-cols-2 gap-4">
              <div class="flex items-center text-gray-600">
                <i class="fas fa-bed mr-2"></i>
                <strong>Quartos:</strong> {{ imovel.bedrooms }}
              </div>
              <div class="flex items-center text-gray-600">
                <i class="fas fa-bath mr-2"></i>
                <strong>Banheiros:</strong> {{ imovel.bathrooms }}
              </div>
              <div class="flex items-center text-gray-600">
                <i class="fas fa-person-booth mr-2"></i>
                <strong>Suítes:</strong> {{ imovel.suites }}
              </div>
              <div class="flex items-center text-gray-600">
                <i class="fas fa-ruler mr-2"></i>
                <strong>Área Útil:</strong> {{ imovel.area }} m²
              </div>
              <div class="flex items-center text-gray-600">
                <i class="fas fa-ruler-combined mr-2"></i>
                <strong>Área Total:</strong> {{ imovel.areaTotal }} m²
              </div>
            </div>
          </div>
          <p class="text-gray-700 text-base"><strong>Descrição do Imóvel:</strong><br />{{ imovel.description }}</p>
          <br />
        </div>
        <div class="bg-white shadow-lg rounded-lg p-4 space-y-4 mt-6">
          <form @submit.prevent="submitContactForm" class="space-y-4">
            <div class="flex flex-col items-center justify-center w-full">
              <img src="https://i.ibb.co/RpzLpPm/Whats-App-Image-2024-05-07-at-22-14-13-removebg-preview.png"
                alt="Logo Álvaro Motta Imóveis" class="h-20 mb-4">
              <h2 class="text-3xl font-bold text-gray-900">Entre em contato</h2>
            </div>
            <div class="grid grid-cols-1 gap-y-6">
              <input type="tel" v-model="contactForm.telephone" id="telephone" required @input="formatPhone"
                maxlength="15"
                class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-yellow-500 focus:border-yellow-500"
                placeholder="Seu Telefone">
              <input type="text" v-model="contactForm.name" id="name" required
                class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-yellow-500 focus:border-yellow-500"
                placeholder="Seu nome">
              <textarea v-model="contactForm.message" id="message" rows="4" required
                class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-yellow-500 focus:border-yellow-500"
                placeholder="Qual o tipo de Imóvel que procura?"></textarea>
              <select v-model="contactForm.type" id="type" required
                class="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-yellow-500 focus:border-yellow-500">
                <option disabled value="">Como você nos encontrou?</option>
                <option value="Google">Google</option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="Amigo/Família">Amigo/Família</option>
                <option value="Outro">Outro</option>
              </select>
              <button type="submit"
                class="w-full flex justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-yellow-600 hover:bg-yellow-700">Enviar
                Mensagem</button>
              <a href="https://wa.me/77988059201?text=Olá!%20Gostaria%20de%20mais%20informações." target="_blank"
                class="w-full flex justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-500 hover:bg-green-600">
                Conversar no WhatsApp
              </a>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';
import MapPage from './MapPage';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';

const route = useRoute();
const imovel = ref({});
const showModal = ref(false);
const currentImage = ref(null);
const currentIndex = ref(0);
const contactForm = ref({
  telephone: '',
  name: '',
  message: '',
  type: ''
});

onMounted(async () => {
  try {
    const response = await axios.get(`/imoveis/${route.params.id}`);
    imovel.value = response.data;
  } catch (error) {
    console.error(error);
  }
});
const formatPhone = (event) => {
  let value = event.target.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
  value = value.replace(/(\d)(\d{4})$/, "$1-$2");
  contactForm.value.telephone = value;
};
const submitContactForm = async () => {
  const contactData = {
    telephone: contactForm.value.telephone,
    name: contactForm.value.name,
    message: contactForm.value.message,
    type: contactForm.value.type,
  };

  try {
    const response = await axios.post('/contact', contactData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log('Resposta do servidor:', response.data);
    contactForm.value = { telephone: '', name: '', message: '' };
    alert('Mensagem enviada com sucesso!');
  } catch (error) {
    alert('Erro ao enviar mensagem, tente novamente.');
  }
};

const getImageUrl = (imovelId, imageUrl) => {
  const baseURL = 'https://alvaromottaback-production.up.railway.app/';
  const url = imageUrl.startsWith('/') ? imageUrl.substring(1) : imageUrl;
  return `${baseURL}${url}`;
};

const openModal = (image) => {
  currentImage.value = image;
  currentIndex.value = imovel.value.images.findIndex(i => i.id === image.id);
  showModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
};

const handleOverlayClick = () => {
  closeModal();
};

const nextImage = () => {
  currentIndex.value = (currentIndex.value + 1) % imovel.value.images.length;
  currentImage.value = imovel.value.images[currentIndex.value];
};

const previousImage = () => {
  currentIndex.value = (currentIndex.value - 1 + imovel.value.images.length) % imovel.value.images.length;
  currentImage.value = imovel.value.images[currentIndex.value];
};

const formatCurrency = (value) => {
  const number = Number(value);
  return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};
</script>

<style scoped>
.showModal {
  position: fixed;
  z-index: 1000;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 1rem;
  }
  
  .modal-content {
    max-height: 90vh;
    overflow: auto;
  }
  
  .fixed-size {
    width: 250px;
    height: 250px;
  }
  
  img {
    max-width: 100%;
}
</style>
