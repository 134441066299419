<template>
    <div class="text-center p-6 lg:p-12 bg-cover bg-center relative"
        style="background-image: url('https://www.imoveisdestaque.com.br/site/assets/images/page-titles/venda.jpg'); min-height: 300px;">
        <div class="absolute inset-0 bg-black bg-opacity-30"></div>
        <h2 class="text-white text-xl md:text-2xl lg:text-3xl font-bold z-10 relative">Onde novas histórias começam:
            encontre seu próximo lar conosco!</h2>
        <p class="text-white text-md md:text-xl lg:text-2xl mt-2 z-10 relative">Descubra o lugar perfeito para criar
            memórias
            inesquecíveis.</p>
        <form class="mt-4 z-10 relative flex flex-col sm:flex-row justify-center items-center gap-4"
            @submit.prevent="emitFilters">
            <input v-model="search" type="text" placeholder="Pesquise por imóvel, cidade... "
                class="w-full sm:w-2/3 md:w-1/2 lg:w-1/3 p-3 rounded-lg shadow-lg focus:ring-2 focus:ring-amber-500 focus:outline-none transition-all">
            <select v-model="type"
                class="w-full sm:w-1/3 md:w-1/4 p-3 rounded-lg shadow-lg focus:ring-2 focus:ring-amber-500 focus:outline-none transition-all">
                <option value="">Tipo de Imóvel</option>
                <option v-for="typeOption in typeOptions" :key="typeOption"
                    :value="typeOption.toLowerCase().replace(/ /g, '')">{{ typeOption }}</option>
            </select>
            <button type="submit"
                class="w-full sm:w-auto bg-amber-400 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-amber-500 transition-all">
                Buscar
            </button>
        </form>
    </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';

const search = ref('');
const type = ref('');
const typeOptions = ['Apartamento', 'Casa', 'Casa em Condominio', 'Terreno', 'Estúdio', 'Sítio', 'Ponto Comercial', 'Fazenda', 'Loja', 'Sala', 'Outro'];

const emit = defineEmits(['apply-filters']);

const normalizeString = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, '').toLowerCase();
};

const emitFilters = () => {
    const normalizedSearch = normalizeString(search.value);
    const normalizedType = normalizeString(type.value);
    emit('apply-filters', { search: normalizedSearch, type: normalizedType });
};
</script>
